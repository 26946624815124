import * as React from 'react'
import { Helmet } from 'react-helmet'
import classNames from 'classnames/bind'
import { InView } from 'react-intersection-observer'

import SEO from 'components/core/SEO'
import Layout from 'components/ui/Layout'
import AnimatedTitle from 'components/ui/AnimatedTitle'

import * as s from './404.module.scss'
const cn = classNames.bind(s)

const NotFound: React.FC = () => {
  return (
    <div>
      <Helmet>
        <html data-bg-blue data-404 />
      </Helmet>
      <SEO title='EVEREST - Page not found' />
      <Layout>
        <InView triggerOnce threshold={0.2}>
          {({ inView, ref }) => (
            <div ref={ref} className={cn('wrapper')}>
              <AnimatedTitle animateIn={inView} text='404 - Page Not Found' />
            </div>
          )}
        </InView>
      </Layout>
    </div>
  )
}

export default NotFound
